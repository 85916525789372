<template>
  <div>
    <v-card class="d-flex justify-start flex-wrap mx-4 my-2" flat>
      <v-select
        autofocus
        class="select"
        item-text="title"
        item-value="bpId"
        :items="LPUList"
        v-model="choosenLPU"
        label="Выберите ЛПУ"
      ></v-select>
      <v-spacer></v-spacer>
      <v-select
        :items="viewTypes"
        item-text="title"
        item-value="value"
        v-model="view"
        label="Тип отображения"
      ></v-select>
      <v-spacer></v-spacer>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="monthText"
            label="Дата"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          min="2017-01"
          max="2030"
          v-model="date"
          type="month"
          no-title
          scrollable
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </v-card>
    <!-- <v-text-field
          v-model="search"
          clearable
          append-icon="mdi-magnify"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field> -->
    <v-data-table
      :headers="tableHeader"
      :items="getStatistics"
      item-key="start_int"
      class="elevation-1"
      :items-per-page="100"
      :search="search"
      mobile-breakpoint="0"
      :loading="loading"
      loading-text="Загрузка... Пожалуйста подождите"
      :footer-props="{
        itemsPerPageOptions: [100, -1],
      }"
    >
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 7),
      viewTypes: [
        { title: 'Интервалы', value: 'int' },
        { title: 'День', value: 'day' },
        { title: 'Месяц', value: 'month' },
      ],
      view: 'month',
      menu: false,
      modal: false,
      choosenLPU: '',
      loading: false,
      search: '',
      objectHeaders: [
        { text: 'Дата', value: 'start_int' },
        { text: 'Всего звонков', value: 'all_call' },
        { text: 'Обслуженных', value: 'obsl' },
        { text: 'Потеряных', value: 'lar_all' },
        { text: 'SLA', value: 'sla' },
        { text: 'LAR', value: 'lar' },
        { text: 'ASA', value: 'ASA' },
        { text: 'Доступность', value: 'reach' },
      ],
      objectHeadersMobile: [
        { text: 'Дата', value: 'start_int' },
        { text: 'Всего звонков', value: 'all_call' },
        { text: 'Доступность', value: 'reach' },
      ],
    }
  },
  methods: {
    ...mapActions(['getReports', 'getSockets']),
    ...mapMutations(['clearLines']),
  },

  async created() {
    this.getSockets()
    // setInterval(async () => {
    //   await this.getMonotoringObjects();
    // }, 15 * 1000);
  },

  computed: {
    ...mapGetters(['getStatistics', 'LPUList']),
    tableHeader() {
      return this.isMobile ? this.objectHeadersMobile : this.objectHeaders
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    dateLPU() {
      return { date: this.date, bpId: this.choosenLPU, view: this.view }
    },
    monthText() {
      return moment(this.date, 'YYYY-MM').locale('ru').format('MMMM')
    },
  },
  watch: {
    async dateLPU(newVal) {
      const { date, bpId, view } = newVal
      if (date && bpId && view) {
        this.loading = true
        this.clearLines()
        await this.getReports(this.dateLPU)
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// .select {
//   width: 80;
// }
</style>